export const cardArray = [
  {
    header: "",
    text: ""
  },
  {
    header: "",
    text: ""
  }
];
