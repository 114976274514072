import "./meeting.css";

export default function Meeting(props) {

  return (
    <div className={props.size === "sm" ? "meeting sm" : "meeting"} id="Meeting">
      <h2>Practice Dates</h2>
      <span className="Date"> December 3, 5, 10, 12, 17, 19 </span>
      <span className="Date"> January 7, 9, 14, 16, 21, 23, 28, 30 </span>
      <span className="Date"> February 4, 6, 11, 13, 18, 20, 25, 27 </span>
      <span className="Date"> March 4, 6, 11, 13, 18 </span>
      <span className="Date"> Tournament Dates will be added and communicated via our Facebook page and Web
      Site as they are decided.</span>
    </div>
  );
}
