import "./about.css";
import { cardArray } from "./aboutInfo";
import React from "react";
import photo1 from "../Assets/point.jpg"
import photo2 from "../Assets/balls.jpg"


export default function About(props) {
  return (
    <div className={props.size === "sm" ? "about-container sm" : "about-container"} id="About">
      {/* {cardArray.map((card, index) => (
          <div className="card" key={index}>
            <span className="about-header">{card.header}</span>
            <span>{card.text}</span>
          </div>
        ))} */}
        <img src={photo1} alt="photo" />
        <button className="FormButton"> <a href="https://docs.google.com/document/d/1GPO4btItWiLqf81pjusdje7SmWxB1adX/edit?usp=sharing&ouid=105499710533681496050&rtpof=true&sd=true" target="blank"> Sign-Up Form </a> </button>
        <img src={photo2} alt="photo" />
    </div>
  );
}
